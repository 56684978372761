import { useTina } from "tinacms/dist/react";
import { PageContent } from "../components/PageContent";
import { getPageStaticProps } from "../utils/pageUtils";

export default function Home(props) {
  const { data } = useTina({
    query: props.query,
    variables: props.variables,
    data: props.data,
  });

  return (
    <PageContent
      page={data.page}
      footerData={props.footerData}
      headerData={props.headerData}
      isHomePage={true}
    />
  );
}

export const getStaticProps = async () => {
  return getPageStaticProps("home.json");
};
